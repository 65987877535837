<template>
    <div id="examinations">
        <div class="container">
            <div class="row mt-3 d-flex justify-content-between">
                <span class="ml-2">
                    <h3>Bodové hodnocení</h3>
                </span>
                <span class="">
                    <i>Pololetí:</i>
                    <b-form-select @change="reload()" size="sm" v-model="selectedSemester" :options="semesters"></b-form-select>
                </span>
                <span>
                    <b-button size="sm" variant="success" v-b-modal.bulk-exam-creation
                        >Přidat známky</b-button
                    >
                </span>
            </div>
            <hr />
            <div class="row">
                <div class="col-12">
                    <b-spinner v-if="!rendered" class="center" label="Loading..."></b-spinner>
                    <StudentExamTable
                        v-show="rendered"
                        @reload="reload"
                        v-for="(exam, idx) in exams"
                        :key="idx"
                        :wholeExam="exam"
                        :examTypes="fixExamTypes"
                        :subjects="fixSubjects"
                        class="mb-3"
                    ></StudentExamTable>
                </div>
            </div>
        </div>
        <b-modal
            id="bulk-exam-creation"
            @show="bulkModal"
            title="Hromadné přidání známky"
            ok-only
            okTitle="Přidat"
            @ok="createBulkExams"
            ref="bulk-exam-creation"
            :ok-disabled="submitState"
        >
            <div class="">
                <b-form class="row">
                    <b-form-group label="Datum" label-for="dt-examDate" class="col-6">
                        <DatePicker
                            :monday-first="true"
                            :disabled-dates="{}"
                            format="dd.MM.yyyy"
                            v-model="bulkExam.date"
                            :bootstrap-styling="true"
                            id="dp-examDate"
                        ></DatePicker>
                    </b-form-group>
                    <b-form-group
                        label="Typ známky"
                        label-for="sel-examType"
                        class="col-6"
                        :state="examTypeState"
                        invalid-feedback="Vyberte typ známky"
                    >
                        <b-form-select
                            id="sel-examType"
                            :options="examTypes"
                            text-field="name"
                            value-field="id"
                            v-model="bulkExam.type"
                            @input="updateOnTypeSelection"
                        ></b-form-select>
                    </b-form-group>
                    <b-form-group
                        label="Název"
                        label-for="text-examName"
                        class="col-12"
                        :state="nameState"
                        invalid-feedback="Název má alepoň 3 znaky"
                    >
                        <b-form-input
                            id="text-examName"
                            v-model="bulkExam.name"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label="Předmět"
                        label-for="sel-subject"
                        class="col-6"
                        :state="subjectState"
                        invalid-feedback="Vyberte předmět známky"
                    >
                        <b-form-select
                            id="sel-subject"
                            :options="subjects"
                            text-field="name"
                            value-field="id"
                            v-model="bulkExam.subject"
                        ></b-form-select>
                    </b-form-group>
                </b-form>
                <div class="col-12 my-2 no-gutters">
                    <b-button variant="warning" size="sm" @click="selectAllRows"
                        >Vybrat všechny</b-button
                    >
                    <b-button variant="warning" class="ml-3" size="sm" @click="clearSelected"
                        >Odebrat všechny</b-button
                    >
                </div>
                <div class="col-12 g-0">
                    <table class="table fixed">
                        <tbody>
                            <tr v-for="(student, idx) in bulkExam.students" :key="idx">
                                <td
                                    @click="student.selected = !student.selected"
                                    style="cursor: pointer"
                                >
                                    <a>{{ student.name }}</a>
                                </td>
                                <td v-if="student.selected" class="text-center">
                                    <b-form-input
                                        type="number"
                                        v-model.number="student.earned"
                                        min="-1"
                                        :max="bulkModalMaximum"
                                        size="sm"
                                        class="w-50 d-inline"
                                        @update="earnedPointsCheck(student)"
                                    >
                                    </b-form-input>
                                    <p class="ml-1 d-inline">
                                        <strong>/ {{ bulkModalMaximum }}</strong>
                                    </p>
                                </td>
                                <td
                                    v-else
                                    @click="student.selected = !student.selected"
                                    style="cursor: pointer"
                                >
                                    ❌
                                </td>
                                <td>
                                    <b-form-checkbox v-model="student.absent">
                                        Absence
                                    </b-form-checkbox>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </b-modal>
        <datalist id="students-datalist">
            <option v-for="(exam, idx) in exams" :key="idx">{{ exam.student.name }}</option>
        </datalist>
    </div>
</template>

<script>
import StudentExamTable from '../components/StudentExamTable';
import DatePicker from 'vuejs-datepicker';

export default {
    name: 'Examinations',
    components: {
        StudentExamTable,
        DatePicker
    },
    computed: {
        examTypeState() {
            return !!this.bulkExam.type;
        },
        subjectState() {
            return !!this.bulkExam.subject;
        },
        nameState() {
            if (this.bulkExam.name) {
                return this.bulkExam.name.length >= 3;
            } else {
                return false;
            }
        },
        submitState() {
            return !this.examTypeState || !this.subjectState || !this.nameState;
        },
        fixExamTypes() {
            return JSON.parse(JSON.stringify(this.examTypes));
        },
        fixSubjects() {
            return JSON.parse(JSON.stringify(this.subjects));
        }
    },
    data() {
        return {
            textSearch: undefined,
            exams: [],
            subjects: [],
            examTypes: [],
            maxTableRowLength: 0,
            selected: [],
            tableFields: [
                'selected',
                {
                    key: 'student.name',
                    label: 'Student Name'
                }
            ],
            bulkExam: {
                date: Date.now(),
                type: undefined,
                subject: undefined,
                name: undefined,
                students: []
            },
            bulkModalMaximum: 0,
            rendered: false,
            semesters: [
                { value: '2022-2', text: 'Druhé pololetí 2022'},
                { value: '2023-1', text: 'První pololetí 2023'},
                { value: '2023-2', text: 'Druhé pololetí 2023'}
            ],
            selectedSemester: '2023-1',
        };
    },
    methods: {
        earnedPointsCheck(student) {
            if (student.earned == -1) {
                student.earned = 0;
                student.absent = true;
            } else {
                student.absent = false;
            }
        },
        async createBulkExams(event) {
            event.preventDefault();
            let be = this.bulkExam;

            if (
                !be.name ||
                be.name == '' ||
                be.earned > this.bulkModalMaximum ||
                !be.type ||
                !be.subject ||
                be.students.length < 1
            ) {
                alert('Neplatné údaje');
                return;
            }

            be.students.forEach((student) => {
                if (student.absent) {
                    student.earned = 0;
                }
            });

            be.date = this.$moment(be.date).format('YYYY-MM-DD');

            try {
                const post = await this.axios.post('/api/exam/bulk', be);
                if (post.status == 204) {
                    this.$refs['bulk-exam-creation'].hide();
                    this.reload();
                }
            } catch (err) {
                alert('error, viz console');
                console.error(err);
            }
            be.date = Date.now();
        },
        selectAllRows() {
            for (let x of this.bulkExam.students) {
                x.selected = true;
            }
        },
        clearSelected() {
            for (let x of this.bulkExam.students) {
                x.selected = false;
            }
        },
        onRowSelected(items) {
            this.selected = [];
            items.forEach((item) => {
                this.selected.push({ id: item.student.id, name: item.student.name });
            });
        },
        updateOnTypeSelection() {
            let newMaximum = this.examTypes.find((o) => o.id == this.bulkExam.type);
            newMaximum
                ? (this.bulkModalMaximum = newMaximum.maximum)
                : (this.bulkModalMaximum = '-');
        },
        async reload() {
            this.rendered = false;
            this.axios.get('/api/examsummary?semester=' + this.selectedSemester).then((res) => {
                this.exams = this.sortNames(res.data);
                this.rendered = true;
            });
            const get_types = await this.axios.get(`/api/types/active`);
            this.examTypes = get_types.data;
        },
        bulkModal() {
            if (this.bulkExam.students.length == 0) {
                this.exams.forEach((el) => {
                    this.bulkExam.students.push({
                        id: el.student.id,
                        name: el.student.name,
                        selected: false,
                        earned: 0,
                        absent: true
                    });
                });
            }
        },
        sortNames(data) {
            return data.sort((a, b) => {
                try {
                    let name1 = a.name.trim().split(' ')[0].toLowerCase();
                    let name2 = b.name.trim().split(' ')[0].toLowerCase();
                    
                    return name1.localeCompare(name2, 'cs-CZ');
                } catch (error) {
                    return false;
                }
            });
        }
    },
    async created() {
        const teachersexaminations = await this.axios.get('/api/examsummary');
        this.exams = teachersexaminations.data;

        this.exams.forEach((y) => {
            let temp = Object.entries(y.data);
            temp.forEach((z) => {
                this.maxTableRowLength < z[1].length ? (this.maxTableRowLength = z[1].length) : {};
            });
        });

        this.exams = this.sortNames(this.exams);

        this.rendered = true;

        const examTypes = await this.axios.get('/api/types/active');
        this.examTypes = examTypes.data;

        const examSubjects = await this.axios.get('/api/mysubjects');
        this.subjects = examSubjects.data;
    }
};
</script>

<style>
.subj-name {
    max-width: 2rem !important;
}

.add-btn {
    padding: 0 6px !important;
}

.p-tooltip {
    margin-bottom: 5px;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
}
</style>
