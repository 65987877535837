<template>
    <div id="student-evaluations">
        <div class="container mt-2" v-if="!unauthorized">
            <div class="row no-gutters">
                <h2>Hodnocení žáka {{ student.name }}</h2>
            </div>
            <div class="row mt-3 no-gutters">
                <b-tabs class="col-12" lazy>
                    <b-tab title="timeline" @click="getTimeline">
                        <div v-if="timelineData.length == 0" class="row">
                            <p class="col-12 text-center mt-5">
                                <b>Zatím zde nic není...</b>
                            </p>
                        </div>
                        <div v-for="(event, idx) in timelineData" :key="idx" class="mt-3">
                            <div class="row mb-3" v-if="event.type == 1">
                                <p class="col-md-3 col-12">
                                    <strong>{{ leftDate(idx, event) }}</strong>
                                </p>
                                <small class="col-1 text-muted">
                                    {{ $moment(event.created).format('HH:mm') }}</small
                                >
                                <div class="col-md-8 col-11">
                                    {{ event.text }}
                                    <sub>{{ fromDate(event) }}</sub>
                                </div>
                            </div>
                            <div class="row mb-3" v-if="event.type == 2">
                                <p class="col-md-3">
                                    <strong>{{ leftDate(idx, event) }}</strong>
                                </p>
                                <small class="col-1 text-muted">
                                    {{ $moment(event.created).format('HH:mm') }}</small
                                >
                                <p class="col-6">
                                    {{ event.subject_name }} ; {{ event.type_name }} ;
                                    {{ event.exam_name }}
                                    <sub>{{ fromDate(event) }}</sub>
                                </p>
                                <p v-if="!event.absent" class="col-2">
                                    {{ event.earned }} z {{ event.maximum }}
                                </p>
                                <p v-if="event.absent" class="col-2">A z {{ event.maximum }}</p>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="souhrn" @click="getSummary">
                        <div v-if="summary.length == 0" class="row">
                            <p class="col-12 text-center mt-5">
                                <b>Zatím zde nic není...</b>
                            </p>
                        </div>
                        <div class="row mt-3">
                            <div v-for="sum in summary" :key="sum.name" class="col-12 mt-3">
                                <h4 class="d-inline mr-2">{{ sum.name }}:</h4>
                                <p class="d-inline">
                                    získáno <strong>{{ sum.earned }}</strong> z maxima
                                    <strong>{{ sum.maximum }}</strong> bodů což činí
                                    <strong>{{ avgPercent(sum.earned, sum.maximum) }}%</strong>
                                </p>
                                <br />
                                <GradeChart
                                    :width="500"
                                    :grade_tresholds="grade_tresholds"
                                    :grade_percent="avgPercent(sum.earned, sum.maximum)"
                                ></GradeChart>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="náplně týdne" @click="getPrograms">
                        <div class="row mt-3">
                            <b-button size="sm" class="mx-3" :variant="registerBtnVariant" v-if="pushAvalible" @click="subscribe()">
                                {{ this.registerButtonText }}
                            </b-button>
                        </div>
                        <div v-if="programs.length == 0" class="row">
                            <p class="col-12 text-center mt-5">
                                <b>Zatím zde nic není...</b>
                            </p>
                        </div>
                        <div class="row mt-3" v-for="program in programs" :key="program.id">
                            <!-- {{ program }} -->
                            <p class="col-3">
                                <b>
                                    {{ formatWeekDateStr(program.date) }}
                                </b>
                            </p>
                            <b-form-textarea no-resize :no-auto-shrink="true" readonly  class="col-9" v-model="program.text">
                            </b-form-textarea>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
        <div class="container" v-if="unauthorized">
            <h1 class="text-danger display-3">Neoprávěný přístup</h1>
            <p>Kontaktujte učitele nebo administrátor</p>
        </div>
    </div>
</template>

<script>
import GradeChart from '../components/GradeChart';

export default {
    name: 'StudentEvaluations',
    data() {
        return {
            evaluations: [],
            programs: [],
            student: {},
            token: undefined,
            nocount: false,
            unauthorized: false,
            months: 2,
            options: [...Array(13).keys()].splice(1, 12),
            timelineData: [],
            summary: [],
            grade_tresholds: [],
            registerButtonText: 'Zaregistrovat notifikace',
            sw: undefined,
            registerBtnVariant: 'success'
        };
    },
    components: {
        GradeChart
    },
    computed: {
        pushAvalible() {
            return 'PushManager' in window && !!navigator.serviceWorker;
        }
    },
    methods: {
        formatWeekDateStr(date) {
            let currentWeek = (this.$moment(date).week());
            let startOfWeek = this.$moment(date).startOf('week');
            let endOfWeek = this.$moment(date).endOf('week');

            return `Týden č. ${currentWeek} (${startOfWeek.format("DD.MM.YYYY")} - ${endOfWeek.format("DD.MM.YYYY")})`;
        },
        async reg() {
            this.sw = await navigator.serviceWorker.register('/api/test/sw.js', {
                scope: '/'
            });
            // console.log(this.sw);
        },
        async subscribe() {
            await this.reg();

            if (this.registerButtonText == 'Zrušit notifikace') {
                await navigator.serviceWorker.getRegistrations().then(function (registrations) {
                    registrations.forEach(function (v) {
                        console.log('Unregister notifications');
                        v.unregister();
                    });
                });

                this.registerButtonText = 'Zaregistrovat notifikace';
                this.registerBtnVariant = 'success';

            } else {
                let sw = await navigator.serviceWorker.ready;
                let subscription = await sw.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey:
                        'BLw0k5P3XT675mh7KT9ttVFOHi5rFEjUmeMlKDhPhFUtL8COCcjkXTxOIRi3FmLaWMLbNYaRgPu-B6uSl5o-J8M'
                });

                const subsc = this.axios
                    .post(`/api/push/subscribe/` + this.token, subscription)
                    .then((x) => {
                        this.registerButtonText = 'Zrušit notifikace';
                        this.registerBtnVariant = 'danger';
                    });
            }
        },

        avgPercent(ear, max) {
            let avg = (ear / max) * 100;
            return Math.round(avg);
        },
        updateEvals() {
            this.axios
                .get(`/api/evals/months/${this.months}`, {
                    params: { token: this.token }
                })
                .then((response) => {
                    this.evaluations = response.data;
                })
                .catch((err) => {
                    if (err.response.status == 401) {
                        this.unauthorized = true;
                    } else {
                        console.error(err);
                    }
                });
        },
        fromDate(event) {
            let from = this.$moment(event.date);
            let created = this.$moment(event.created);
            created.startOf('day');

            if (from.format() == created.format()) {
                return '';
            } else {
                return 'ze dne ' + from.format('DD.MM.');
            }
        },
        leftDate(idx, event) {
            let td = this.timelineData;

            if (td[idx - 1] != undefined) {
                let now = this.$moment(event.created);
                let nowMinusN = this.$moment(td[idx - 1].created);
                now.startOf('day');
                nowMinusN.startOf('day');

                if (now.format() == nowMinusN.format()) {
                    return '';
                } else {
                    return this.$moment(td[idx].created).format('dddd D. MMMM');
                }
            } else {
                return this.$moment(td[idx].created).format('dddd D. MMMM');
            }
        },
        async getTimeline() {
            const get = await this.axios.get(`/api/timeline`, {
                params: { token: this.token }
            });
            this.timelineData = get.data;
        },
        async getSummary() {
            const get = await this.axios.get(`/api/summary`, {
                params: { token: this.token }
            });
            this.summary = get.data;
            const grades = await this.axios.get(`/api/grades/student`, {
                params: { token: this.token }
            });
            this.grade_tresholds = grades.data;
        },
        async getPrograms() {
            const get = await this.axios.get(`/api/programs/` + this.token);
            this.programs = get.data;
            console.log(this.programs);
        },
        async getServiceWorkerRegistrations() {
            return await navigator.serviceWorker.getRegistrations();
        },
        wtf() {
            this.registerButtonText == 'Zrušit notifikace';
        }
    },
    async mounted() {
        this.token = this.$route.params.token;

        if (this.$route.fullPath.substr(this.$route.fullPath.length - 7) == 'nocount') {
            try {
                const studentinfo = await this.axios.get(`/api/studentinfo`, {
                    params: { token: this.token, count: 'no' }
                });
                this.student = studentinfo.data;
            } catch (err) {
                if (err.response.status == 401) {
                    this.unauthorized = true;
                    return;
                } else {
                    alert('error, viz console');
                    console.error(err);
                }
            }
        } else {
            try {
                const studentinfo = await this.axios.get(`/api/studentinfo`, {
                    params: { token: this.token }
                });
                this.student = studentinfo.data;
            } catch (err) {
                if (err.response.status == 401) {
                    this.unauthorized = true;
                    return;
                } else {
                    alert('error, viz console');
                    console.error(err);
                }
            }
        }

        this.getTimeline();
        this.getSummary();
        this.getPrograms();

        const regs = await this.getServiceWorkerRegistrations()
        if (regs.length > 0) {
            this.registerButtonText = 'Zrušit notifikace';
            this.registerBtnVariant = 'danger';
        }
    }
};
</script>

<style></style>
